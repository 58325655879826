import { render, staticRenderFns } from "./ProductDetail.vue?vue&type=template&id=71dc6202"
import script from "./ProductDetail.vue?vue&type=script&lang=js"
export * from "./ProductDetail.vue?vue&type=script&lang=js"
import style0 from "./ProductDetail.vue?vue&type=style&index=0&id=71dc6202&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QSkeleton from 'quasar/src/components/skeleton/QSkeleton.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QRating from 'quasar/src/components/rating/QRating.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QColor from 'quasar/src/components/color/QColor.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QSkeleton,QImg,QRating,QItemLabel,QChip,QAvatar,QBtn,QItem,QItemSection,QBadge,QColor,QIcon});
