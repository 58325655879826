<template>
  <div class="sidebarLeft">
    <div class="filterBar">
      <q-item-label v-if="productsBy !== 'DT'" class="text-h5">
        <div class="row">
          <div class="col"> Filters </div>
          <div class="col-auto">
            <q-btn
              unelevated
              no-caps
              rounded
              outline
              color="primaryOnBody"
              label="Clear All"
              size="10px"
              v-if="selectedFilters.length"
              @click="clearFilter"
            />
          </div>
        </div>
      </q-item-label>
      <q-list v-if="blnShowCategoryTree">
        <q-expansion-item dense default-opened>
          <template v-slot:header>
            <q-item-section class="toggleTitle"> Categories </q-item-section>
          </template>

          <div class="custom-scroll scroll-style-1" style="max-height: 200px">
            <q-tree
              :nodes="categoryTree"
              expandable-key="hasChild"
              children-key="childCategory"
              node-key="categoryID"
              :selected.sync="selectedCategoryId"
              selected-color="primaryOnBody"
              accordion
              no-connectors
              :expanded.sync="expandedKeys"
              class="filterCategory q-mx-md"
            >
              <!-- @update:selected="categorySelected" -->
              <template v-slot:default-header="prop">
                <div class="row items-center">
                  <router-link
                    :to="{
                      name: 'Category',
                      params: { seoName: prop.node.seoName },
                    }"
                    :class="{
                      'text-primaryOnBody': selectedKeys.includes(
                        prop.node.categoryID
                      ),
                    }"
                  >
                    {{ $options.filters.titleCase(prop.node.categoryName) }}
                  </router-link>
                  <!-- <div
                :class="{
                  'text-primary': selectedKeys.includes(prop.node.categoryID),
                }"
              >
                {{ prop.node.categoryName }}
              </div> -->
                </div>
              </template>
            </q-tree>
          </div>
          <!-- <q-scroll-area
            :thumb-style="{
              ...scrollAreaThumbStyle,
              zIndex: 2000,
            }"
            :bar-style="{ ...scrollAreaBarStyle, zIndex: 2000 }"
            style="height: 200px; max-width: 300px"
            class="q-mb-md"
            visible
          ></q-scroll-area> -->
        </q-expansion-item>
      </q-list>

      <DiscountList v-if="showDiscounts" />
      <!-- <InnerLoadingSpinner showing /> -->

      <template v-if="Object.keys(filters).length">
        <q-list v-for="(filter, key) of filterOptions" :key="key">
          <q-expansion-item
            v-if="
              filter.filterValues &&
              (!ModifiedFilterCodes.includes(key) ||
                (ModifiedFilterCodes.includes(key) &&
                  isPriceFilterAvailable(key)))
            "
            dense
            default-opened
          >
            <template v-slot:header>
              <q-item-section class="toggleTitle">
                {{ filter.filterName }}
              </q-item-section>
            </template>
            <template
              v-if="
                !ModifiedFilterCodes.includes(key) &&
                filter.filterValues.length > 5
              "
            >
              <q-input
                dense
                :ref="key + 'Search'"
                filled
                v-model.trim="search[key]"
                class="q-mb-md q-mx-md"
                :placeholder="'Search ' + filter.filterName"
              >
                <!-- :label="'Search ' + filter.filterName" -->

                <template v-slot:append>
                  <q-icon
                    v-if="search[key] !== ''"
                    name="clear"
                    class="cursor-pointer"
                    @click="resetFilter(key)"
                  />
                </template>
              </q-input>
              <q-scroll-area
                :thumb-style="{ ...scrollAreaThumbStyle, zIndex: 2000 }"
                :bar-style="{ ...scrollAreaBarStyle, zIndex: 2000 }"
                style="height: 150px; max-width: 300px"
                class="q-mb-md q-mx-md"
              >
                <q-tree
                  :no-results-label="`No ${filter.filterName} available matching &quot;${search[key]}&quot;`"
                  :no-nodes-label="`No ${filter.filterName} available.`"
                  :nodes="filter.filterValues"
                  label-key="filterValue"
                  node-key="filterValue"
                  :class="{ emptytree: filter.filterValues.length == 0 }"
                  tick-strategy="strict"
                  :ticked.sync="filters[key]"
                  accordion
                  :filter="search[key]"
                  @update:ticked="filterModified(key)"
                  :selected.sync="selected[key]"
                  @update:selected="selectedFilter(key)"
                  :ref="'Tree' + key"
                />
              </q-scroll-area>
              <q-btn
                v-show="filters && filters[key] && filters[key].length > 0"
                unelevated
                no-caps
                dense
                outline
                color="tertiary"
                size="sm"
                class="q-px-md q-mt-md q-ml-lg"
                label="Clear"
                @click="clearSelection(key)"
              />
            </template>
            <template v-else-if="!ModifiedFilterCodes.includes(key)">
              <q-tree
                :no-results-label="`No ${filter.filterName} available matching &quot;${search[key]}&quot;`"
                :no-nodes-label="`No ${filter.filterName} available.`"
                :nodes="filter.filterValues"
                label-key="filterValue"
                node-key="filterValue"
                :class="{ emptytree: filter.filterValues.length == 0 }"
                tick-strategy="strict"
                :ticked.sync="filters[key]"
                accordion
                :filter="search[key]"
                @update:ticked="filterModified(key)"
                :selected.sync="selected[key]"
                @update:selected="selectedFilter(key)"
                :ref="'Tree' + key"
              />
            </template>
            <template v-else>
              <q-card>
                <q-card-section>
                  <q-range
                    color="primaryOnBody"
                    class="category-price-filter"
                    v-model="filters[key]"
                    :min="priceRange.min"
                    :max="priceRange.max"
                    :left-label-value="'$ ' + filters[key].min"
                    :right-label-value="'$ ' + filters[key].max"
                    label-always
                    :step="step"
                    @change="filterModified(key)"
                  />
                </q-card-section>
              </q-card>
            </template>
          </q-expansion-item>
        </q-list>
      </template>

      <template v-if="productsBy !== 'DT' && loadingFilters">
        <div v-for="n in 2" :key="n" class="q-mb-md q-mt-md">
          <q-skeleton width="80%" height="20px" class="q-mb-md" />
          <q-skeleton type="text" width="60%" height="32px" />
          <q-skeleton type="text" width="40%" height="32px" />
          <q-skeleton type="text" width="55%" height="32px" />
          <q-skeleton type="text" width="30%" height="32px" />
          <q-skeleton type="text" width="45%" height="32px" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'FilterBar',
  props: {
    priceRange: {
      type: Object,
      default: () => {
        return {}
      },
    },
    category: {
      type: Object,
      default: () => {
        return {}
      },
    },
    discount: {
      type: Object,
      default: () => {
        return {}
      },
    },
    filterOptions: {
      type: Object,
      default: () => {
        return {}
      },
    },
    filterInit: {
      type: Object,
      default: () => {
        return {}
      },
    },
    searchOptions: {
      type: Object,
      default: () => {
        return {}
      },
    },
    blnShowCategoryTree: {
      type: Boolean,
      default: false,
    },
    showDiscounts: {
      type: Boolean,
      default: false,
    },
    loadingFilters: {
      type: Boolean,
      default: false,
    },
    productsBy: {
      type: String,
      default: '',
    },
  },
  components: {
    DiscountList: () => import('./DiscountList'),
  },
  data() {
    return {
      filters: { ...this.filterInit },
      selected: { ...this.searchOptions },
      search: { ...this.searchOptions },
      ModifiedFilterCodes: ['PRIC'],
      categoryModel: false,
      selectedCategory: false,
      expandedKeys: [],
      selectedCategoryId: '',
      step: 1,
      clearAll: false,
    }
  },
  computed: {
    categoryTreeInfo() {
      return this.getCategoryTreeInfoById(this.category.categoryID)
    },
    categoryTree() {
      if (this.categoryTreeInfo) return [this.categoryTreeInfo.parent]
      return []
    },
    selectedKeys() {
      if (this.categoryTreeInfo) {
        let selectedKeys = [...this.categoryTreeInfo.parentsOfSelected]

        selectedKeys.push(this.category.categoryID)

        return selectedKeys
      }
      return []
    },
    selectedFilters: {
      get() {
        let selectedFilters = []

        for (let key in this.filterOptions) {
          if (
            (!this.ModifiedFilterCodes.includes(key) &&
              this.filters[key].length) ||
            (key == 'PRIC' &&
              (this.filters.PRIC.min != this.priceRange.min ||
                this.filters.PRIC.max != this.priceRange.max))
          ) {
            selectedFilters.push({
              label: this.filterOptions[key].filterName,
              value: key,
            })
          }
        }

        return selectedFilters
      },
    },
    ...mapGetters('category', ['getCategoryTreeInfoById', 'loadingCategories']),
    ...mapGetters('discounts', ['discounts', 'hasMoreDiscount', 'loadingMore']),
  },
  methods: {
    clearSelection(key) {
      let refElement = 'Tree' + key

      this.$refs[refElement][0].setTicked(this.filters[key], false)
    },
    categorySelected(target) {
      if (target && target !== this.category.categoryID) {
        this.$router
          .push({
            name: 'Category',
            params: { seoName: this.category.seoName },
          })
          .catch(() => {})
      }
    },
    resetFilter(key) {
      let focusElement = key + 'Search'

      this.search[key] = ''
      this.$refs[focusElement][0].focus()
    },
    filterModified(key) {
      if (!this.clearAll) {
        this.$emit('filter-modified', this.filters, key)
      }
    },
    selectedFilter(key) {
      let refElement = 'Tree' + key
      let isTicked = this.$refs[refElement][0].isTicked(this.selected[key])
      this.$refs[refElement][0].setTicked(this.selected[key], !isTicked)
      this.selected[key] = ''
    },
    isPriceFilterAvailable(key) {
      return (
        key == 'PRIC' &&
        this.filters[key]?.hasOwnProperty('min') &&
        this.filters[key]?.hasOwnProperty('max') &&
        this.filters[key].min !== this.filters[key].max &&
        this.filters[key].min >= 0 &&
        this.filters[key].max >= this.filters[key].min
      )
    },
    loadMoreDiscount() {
      this.$store.dispatch('discounts/getDiscounts', {
        loadMore: true,
      })
    },
    clearFilter() {
      this.clearAll = true
      for (let filter of this.selectedFilters) {
        let key = filter.value
        if (key == 'PRIC') {
          this.filters[key].min = this.priceRange.min
          this.filters[key].max = this.priceRange.max
        } else {
          this.clearSelection(key)
          if (this.search[key] !== '') this.search[key] = ''
        }
      }
      this.clearAll = false
      this.filterModified()
    },
  },
  watch: {
    filterInit: function (newVal, oldVal) {
      this.filters = Object.assign({}, newVal)
    },
    categoryTreeInfo() {
      if (this.blnShowCategoryTree && this.categoryTreeInfo) {
        for (let key of this.categoryTreeInfo.parentsOfSelected) {
          this.expandedKeys.push(key)
        }

        if (this.category.hasChild) {
          this.expandedKeys.push(this.category.categoryID)
        }

        this.selectedCategoryId = this.category.categoryID
      }
    },
  },
  created() {
    // if (this.priceRange.max <= 20) {
    //   return (this.step = 2)
    // } else if (this.priceRange.max > 20 && this.priceRange.max <= 100) {
    //   return (this.step = 20)
    // } else if (this.priceRange.max > 100 && this.priceRange.max <= 200) {
    //   return (this.step = 50)
    // } else if (this.priceRange.max > 200) {
    //   return (this.step = 100)
    // } else {
    //   return (this.step = 1)
    // }
    // this.step = parseInt(this.priceRange.max / 10)
    if (this.blnShowCategoryTree && this.categoryTreeInfo) {
      for (let key of this.categoryTreeInfo.parentsOfSelected) {
        this.expandedKeys.push(key)
      }
      if (this.category.hasChild) {
        this.expandedKeys.push(this.category.categoryID)
      }
      this.selectedCategoryId = this.category.categoryID
    }
  },
}
</script>
<style lang="scss">
.emptytree {
  padding-left: 15px;
  margin-top: 5px;
}
.filterBar {
  .text-h5 {
    margin: 0;
    padding: 0 16px;
    font-size: 18px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    color: #314154;
    @media (min-width: 1681px) {
      font-size: 24px;
    }
  }
  .q-list {
    margin: 15px 0 0;
    .q-item {
      .q-focus-helper {
        display: none;
      }
      .toggleTitle {
        font-size: 14px;
        font-weight: bold;
        color: #314154;
        @media (min-width: 1681px) {
          font-size: 18px;
        }
      }
    }
    .q-expansion-item__content {
      // padding-top: 10px;
      .q-radio__label,
      .q-checkbox__label {
        color: #314154;
        font-size: 14px;
        @media (min-width: 1681px) {
          font-size: 18px;
        }
      }
      .scroll {
        .q-tree__node-header {
          padding-left: 9px;
        }
      }
      .q-slider {
        width: 88%;
        margin: 15px auto 0;
      }
    }
    .filterCategory {
      .q-focus-helper {
        display: none;
      }
      .q-tree__arrow--rotate,
      .q-tree__arrow {
        order: 3;
        font-size: 0;
        width: 12px;
        height: 12px;
        &:before {
          content: '';
          width: 8px;
          height: 8px;
          display: block !important;
          border-bottom: 2px solid rgba(49, 65, 84, 0.5);
          border-left: 2px solid rgba(49, 65, 84, 0.5);
          transform: rotate(-135deg);
        }
      }
      a {
        font-size: 13px;
        color: #314154;
        text-decoration: none;
        &.text-secondary {
          font-weight: bold;
        }
        &:hover {
          color: $primaryOnBody;
          color: var(--q-color-primaryOnBody) !important;
        }
      }
      .q-tree__children {
        padding-left: 15px;
      }
      .q-tree__node {
        padding-left: 0;
      }
      .q-tree__node-header-content {
        flex: 0 0 auto;
        padding-right: 10px;
      }
      .q-tree__node--link {
        cursor: unset;
      }
      .q-tree__arrow {
        cursor: pointer;
      }
    }
    // Discount Filter List
    &.list-discount {
      .q-item {
        margin: 10px 0;
        border: 1px solid #ececec;
        align-items: center;
        @include border-radius(4px);
        &.q-router-link--active,
        &.active {
          .q-focus-helper {
            position: absolute;
            top: 0;
            left: 0 /* rtl:ignore */;
            width: 100%;
            height: 100%;
            pointer-events: none;
            border-radius: inherit;

            display: flex;
            background: $secondary;
            background: var(--q-color-secondary);
            opacity: 0.1;
            z-index: 0;
          }
          color: $secondary;
          color: var(--q-color-secondary);
          border-color: rgba(0, 0, 0, 0.1);
          font-weight: bold;
          -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
  .category-price-filter,
  .mobfilterBar {
    .q-slider__thumb-container {
      & + .q-slider__thumb-container {
        .q-slider__pin {
          transform: scale(1) translateY(28px);
        }
        .q-slider__arrow {
          transform: scale(1) translateY(0px) rotate(180deg);
        }
      }
    }
  }
}
body.desktop .category-price-filter,
body.mobile .mobfilterBar {
  .q-slider__thumb-container {
    & + .q-slider__thumb-container {
      .q-slider__pin {
        transform: scale(1) translateY(28px);
      }
      .q-slider__arrow {
        transform: scale(1) translateY(0px) rotate(180deg);
      }
    }
  }
}
</style>
