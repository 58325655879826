<template>
  <div class="autoship-section">
    <div
      class="text-subtitle1 text-weight-bold text-tertiary"
      v-if="isAlreadySubscribe"
      >You can buy with:</div
    >
    <q-list>
      <q-item tag="label" class="no-hover">
        <q-item-section>
          <div class="row items-center">
            <div class="col-auto q-pr-sm">
              <q-radio
                dense
                :value="subscriptionDetails.subscriptionOption"
                :val="1"
                @input="
                  (val) => onChangeSubscribeOption('subscriptionOption', val)
                "
              />
            </div>
            <div class="col">One-time Purchase</div>
            <div
              class="col-auto text-right"
              v-show="product.subscribeDiscount > 0"
            >
              <strong class="text-primary">
                {{ getProductPrice(product).productPrice | currency }}
              </strong>
            </div>
          </div>
        </q-item-section>
      </q-item>

      <q-item tag="label" class="no-hover q-mt-sm">
        <q-item-section>
          <div class="row items-center">
            <div class="col-auto q-pr-sm">
              <q-radio
                dense
                :value="subscriptionDetails.subscriptionOption"
                :val="2"
                @input="
                  (val) => onChangeSubscribeOption('subscriptionOption', val)
                "
              />
            </div>
            <div class="col">
              {{
                product.subscribeDiscount > 0
                  ? 'Subscribe and Save'
                  : 'Subscribe'
              }}
              <q-chip
                square
                size="12px"
                color="green-6"
                text-color="white"
                class="q-ma-none q-py-xs q-px-sm line-height-10 h-auto"
                v-if="product.subscribeDiscount > 0"
              >
                {{ product.subscribeDiscount }}%
              </q-chip>
              <q-btn
                unelevated
                dense
                round
                color="amber-8"
                size="xs"
                class="btn-sub-info"
                v-show="
                  pageName == 'Cart' && product.minimumRecurringOrders > 0
                "
                @click="
                  isMobile &&
                    changeDialogState({
                      dialog: 'tooltipDialog',
                      val: true,
                      properties: {
                        tooltip: `Canceling the subscription before completing ${product.minimumRecurringOrders} recurring orders may result in a subscription cancellation charge.`,
                      },
                    })
                "
              >
                <SvgIcon icon="info" size=".50rem" />
                <q-tooltip
                  content-class="bg-grey-10 text-center"
                  content-style="font-size: 12px;"
                  anchor="top middle"
                  self="bottom middle"
                  max-width="240px"
                  v-if="!isMobile"
                >
                  Canceling the subscription before completing
                  {{ product.minimumRecurringOrders }} recurring orders may
                  result in a subscription cancellation charge.
                </q-tooltip>
              </q-btn>
            </div>
            <div
              class="col-auto text-right"
              v-show="product.subscribeDiscount > 0"
            >
              <span class="text-strike text-caption">
                {{ getProductPrice(product).productPrice | currency }}
              </span>
              <div>
                <strong class="text-primary inline-block">
                  {{ getProductPrice(product).discountedPrice | currency }}
                </strong>
              </div>
            </div>
            <div
              class="col-12 q-pt-sm"
              v-show="subscriptionDetails.subscriptionOption == 2"
            >
              <div class="form-field pb-0">
                <label class="q-field__label q-mb-md">
                  {{ frequencyLabel }}
                </label>
                <q-select
                  dense
                  options-dense
                  outlined
                  :value="subscriptionDetails.frequency"
                  :options="frequencyOptions"
                  option-value="interval"
                  option-label="label"
                  emit-value
                  @input="(val) => onChangeSubscribeOption('frequency', val)"
                  map-options
                />
              </div>
            </div>
          </div>
        </q-item-section>
      </q-item>
    </q-list>
    <TooltipDialog v-if="isMobile" />
  </div>
</template>

<script>
import { hideShowFullSpinner } from 'src/utils'
import { mapGetters } from 'vuex'
import TooltipDialog from 'components/common/label-tooltip.vue'

export default {
  name: 'ProductSubscriptionOptions',
  props: {
    pageName: {
      type: String,
      required: true,
    },
    product: {
      type: Object,
      default: () => {
        return {}
      },
    },
    productId: {
      type: String,
      default: () => {
        return ''
      },
    },
    isAlreadySubscribe: {
      type: Boolean,
      default: false,
    },
    orderId: {
      type: String,
      default: '',
    },
  },
  components: {
    TooltipDialog,
  },
  data() {
    return {
      defaultSubscriptionOption: 2,
      subscriptionOptions: [
        {
          label: 'One-time Purchase',
          value: 1,
        },
        {
          label: 'Subscribe and Save',
          value: 2,
        },
      ],
      subscriptionDetails: {
        subscriptionOption: 1,
        frequency: null,
      },
      frequencyLabel: '',
    }
  },
  computed: {
    ...mapGetters('cart', [
      'currentCartDetails',
      'getProductById',
      'currentCartNumber',
    ]),
    ...mapGetters('persistedLocal', ['getCartPersistedInfo']),
    frequencyOptions() {
      let frequencies = this.product?.frequencies.slice() || []
      const getSubFrequency = (frequency) => {
        return `Every ${frequency} ${frequency > 1 ? 'Days' : 'Day'}`
      }
      return frequencies
        .sort((a, b) => a.intervalDays - b.intervalDays)
        .map((frequency) => {
          return {
            id: frequency.id,
            interval: frequency.intervalDays,
            label: getSubFrequency(frequency.intervalDays),
          }
        })
    },
    isItemInCart() {
      let productInfo = this.getProductById(this.productId)
      return productInfo?.orderLineItemID ? true : false
    },
    cartPersistedInfo() {
      return this.getCartPersistedInfo(this.currentCartNumber)
    },
  },
  methods: {
    initializeSubscriptionDetails() {
      if (
        ['ProductDetail', 'Cart'].includes(this.pageName) &&
        this.isItemInCart
      ) {
        const productInfo = this.getProductById(this.productId)
        const subscriptionDetails =
          this.cartPersistedInfo?.subscriptionDetails?.[
            productInfo.orderLineItemID
          ]

        if (
          subscriptionDetails?.subscriptionOption &&
          subscriptionDetails?.frequency
        ) {
          this.subscriptionDetails = { ...subscriptionDetails }
        }
      } else if (this.pageName === 'ProductDetail' && !this.isItemInCart) {
        this.onChangeSubscribeOption(
          'subscriptionOption',
          this.defaultSubscriptionOption,
          false
        )
      }
    },
    onChangeSubscribeOption(key, val, emitEvent = true) {
      let askForLogin =
        !this.isLoggedIn && key == 'subscriptionOption' && val == 2 && emitEvent
      // Show drawer for guest user when they select subscribe & save option
      if (askForLogin) {
        this.changeDialogState({
          dialog: 'accountDrawer',
          val: true,
        })
        return
      }
      this.subscriptionDetails[key] = val
      // If subscriptionOption is 1 and frequency not set then pick the first one as default
      if (
        this.subscriptionDetails.subscriptionOption == 2 &&
        this.frequencyOptions?.length &&
        !this.subscriptionDetails.frequency
      )
        this.subscriptionDetails.frequency = this.frequencyOptions[0].interval

      if (emitEvent) {
        if (key == 'subscriptionOption') {
          if (
            ['Cart', 'ProductDetail'].includes(this.pageName) &&
            this.isItemInCart
          )
            this.updateCartItem()
          if (this.pageName == 'OrderEdit') this.updateOrder()
        } else if (key == 'frequency') {
          if (this.pageName == 'OrderEdit') this.updateOrder()
        }
        this.$emit('changeSubscription', { ...this.subscriptionDetails })
      }
    },
    getProductPrice(product) {
      let productPrice = this.isItemInCart
        ? this.getProductById(this.productId).price *
          this.getProductById(this.productId).qty
        : product.price

      const discountedPrice = (
        productPrice *
        (1 - product.subscribeDiscount / 100)
      ).toFixed(2)

      return { productPrice: productPrice.toFixed(2), discountedPrice }
    },
    async updateCartItem() {
      try {
        let productInfo = this.getProductById(this.productId)
        let params = {
          fulfillmentMethodId: this.selectedFulfillment?.fulfillmentId,
          products: [
            {
              productId: productInfo.productId,
              qty: productInfo.qty,
              instruction: productInfo.instruction,
              orderLineItemOrderType:
                this.subscriptionDetails.subscriptionOption == 2 ? 1 : 0,
            },
          ],
          apiVersion: this.pageName == 'Cart' ? 'v2' : 'v1',
        }
        hideShowFullSpinner(true)
        await this.$store.dispatch('cart/addUpdateCartDetail', params)
      } catch (err) {
        console.log('err', err)
      } finally {
        hideShowFullSpinner(false)
      }
    },
    updateOrder() {
      try {
        let params = {
          orderID: this.orderId,
          editOrderLineItems: [
            {
              productID: this.product.productId,
              qty: this.product.qty,
              instruction: this.product.instruction,
              orderLineItemOrderType:
                this.subscriptionDetails.subscriptionOption == 2 ? 1 : 0,
              frequency:
                this.subscriptionDetails.subscriptionOption == 2
                  ? this.subscriptionDetails.frequency
                  : 0,
            },
          ],
        }
        this.$store.dispatch('order/updateOrder', params)
      } catch (err) {
        console.log('err', err)
      }
    },
  },
  watch: {
    frequencyOptions(newVal, oldVal) {
      // if (newVal?.length && !this.subscriptionDetails.frequency)
      //   this.subscriptionDetails.frequency = newVal[0].intervalDays
      if (newVal?.length) this.initializeSubscriptionDetails()
    },
  },
  beforeMount() {
    this.initializeSubscriptionDetails()
    if (this.selectedFulfillmentCodeName == 'scd')
      this.frequencyLabel = 'Deliver Me'
    else if (this.selectedFulfillmentCodeName == 'sd')
      this.frequencyLabel = 'Ship Me'
  },
}
</script>

<style lang="scss">
.autoship-section {
  margin-bottom: 10px;
  .radio-group * {
    margin-left: 0px !important;
  }
  .delivery-label {
    font-size: 14px;
    margin-bottom: 10px;
  }
}
</style>
